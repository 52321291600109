import React, { FunctionComponent } from 'react';
import { AppBar } from '../../components/AppBar';
import './HomePage.scss';
import photoFrame from '../../assets/photo-frame.jpeg';
import portrait from '../../assets/portrait.jpeg';
import badge from '../../assets/pink.badge.jpeg';
import facebookIcon from '../../assets/facebook.svg';
import wechatIcon from '../../assets/we-chat.svg';
import linkedIcon from '../../assets/linkedin.svg';
import twitterIcon from '../../assets/twitter.svg';
import case1 from '../../assets/case1.jpeg';
import case2 from '../../assets/case2.jpeg';
import case3 from '../../assets/case3.jpeg';
import case4 from '../../assets/case4.jpeg';
import case5 from '../../assets/case5.jpeg';
import wechatQR from '../../assets/wechat-qr.jpeg';
import whatsappQR from '../../assets/whatapp-qr.jpeg';
import etsyQR from '../../assets/etsy.png';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import Gallery from "react-photo-gallery";

interface HomePageProps {};

const cases = [
    {
        src: case1,
        width: 1,
        height: 1.2
    },
    {
        src: case2,
        width: 3,
        height: 4
    },
    {
        src: case3,
        width: 10,
        height: 10
    },
    {
        src: case4,
        width: 3,
        height: 4
    },
    {
        src: case5,
        width: 3,
        height: 4
    }
];

const HomePage: FunctionComponent<HomePageProps> = () => {
    const { t } = useTranslation();
    return (
      <div className="fui-homepage">
          <AppBar/>
          <div className="fui-homepage__hero">
              <div className="fui-homepage__overlay">
                  <h2 className="fui-homepage__hero-title">
                      {t('homepage.heroimage.title')}
                  </h2>
                  <div className="fui-homepage__hero-sub-title">
                      {t('homepage.heroimage.subtitle')}
                  </div>
                  <Button>{t('homepage.heroimage.getstarted')}</Button>
              </div>
          </div>
          <div className="fui-homepage-section">
              <h2 className="fui-homepage-section-title">{t('homepage.popularproducts')}</h2>
              <div className="fui-homepage-cards">
                  <div className="fui-homepage-card">
                      <img alt="" className="fui-homepage-card__icon" src={portrait}/>
                      <div className="fui-homepage-card__title">
                          {t('homepage.singlepetportrait')}
                      </div>
                      <div className="fui-homepage-card__sub-title">
                          {t('homepage.price')}: {t('homepage.portraitprice')}
                      </div>
                      <div className="fui-homepage-card__button">
                      </div>
                  </div>
                  <div className="fui-homepage-card">
                      <img alt="" className="fui-homepage-card__icon" src={photoFrame}/>
                      <div className="fui-homepage-card__title">
                          {t('homepage.paintingwithframe')}
                      </div>
                      <div className="fui-homepage-card__sub-title">
                          {t('homepage.price')}: {t('homepage.frameprice')}
                      </div>
                      <div className="fui-homepage-card__button">
                      </div>
                  </div>
                  <div className="fui-homepage-card">
                      <img alt="" className="fui-homepage-card__icon" src={badge}/>
                      <div className="fui-homepage-card__title">
                          {t('homepage.petbadge')}
                      </div>
                      <div className="fui-homepage-card__sub-title">
                          {t('homepage.price')}: {t('homepage.badgeprice')}
                      </div>
                      <div className="fui-homepage-card__button">
                      </div>
                  </div>
              </div>
          </div>
          <div className="fui-homepage-section">
              <h2 className="fui-homepage-section-title">{t('homepage.howitworks')}</h2>
              <div className="fui-homepage-contacts">
                  <div className="fui-homepage-contact">
                      <div className="fui-homepage-contact__banner fui-homepage-contact__banner--1st"></div>
                      <h2 className="fui-homepage-contact__title">Wechat</h2>
                      <img alt="" className="fui-homepage-contact__img" src={wechatQR}/>
                  </div>
                  <div className="fui-homepage-contact">
                      <div className="fui-homepage-contact__banner fui-homepage-contact__banner--2nd"></div>
                      <h2 className="fui-homepage-contact__title">WhatsApp</h2>
                      <img alt="" className="fui-homepage-contact__img" src={whatsappQR}/>
                  </div>
                  <div className="fui-homepage-contact">
                      <div className="fui-homepage-contact__banner fui-homepage-contact__banner--3rd"></div>
                      <h2 className="fui-homepage-contact__title">Etsy</h2>
                      <img alt="" className="fui-homepage-contact__img" src={etsyQR}/>
                  </div>
              </div>
          </div>
          <div className="fui-homepage__wave">
              <h2 className="fui-homepage-section-title">{t('homepage.showcases')}</h2>
              <div className="fui-homepage-cases">
                  <Gallery photos={cases}></Gallery>
                {/*<ShowCase/>*/}
              </div>
          </div>
          <div className="fui-homepage__footer">
              2021  © All Rights Reserved by WISDOM Limited Corp.
              <div className="fui-homepage__social">
                  <img alt="" className="fui-homepage-social-icon" src={wechatIcon}/>
                  <img alt="" className="fui-homepage-social-icon" src={linkedIcon}/>
                  <img alt="" className="fui-homepage-social-icon" src={facebookIcon}/>
                  <img alt="" className="fui-homepage-social-icon" src={twitterIcon}/>
              </div>
          </div>
      </div>
    );
}

export default HomePage;

