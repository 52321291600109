import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers, createStore as createReduxStore } from 'redux';
import { StoreState } from './types';
import {
  pageReducer,
  authenticationReducer,
  navigationReducer,
  defaultPageState,
} from './reducers';

const browserHistory = createBrowserHistory();

export const getHistory = () => browserHistory;

const initState: StoreState = {
  page: defaultPageState,
  navigation: {},
};

export const createStore = () => createReduxStore(
  combineReducers({
    page: pageReducer,
    authentication: authenticationReducer,
    router: connectRouter(getHistory()),
    navigation: navigationReducer,
  }),
  initState,
);

export * from './types';
