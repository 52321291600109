import { PageState } from './types';
import { SET_ACTIVE_MENU_ID, SetActiveMenuAction } from "./actions";

export const defaultPageState = {};

export const authenticationReducer = (state = {}) => state;

export const pageReducer = (state: PageState = defaultPageState, action: any) => {
  const { type } = action;
  if (type === '@@router/LOCATION_CHANGE') return defaultPageState;
  // const handler = () => state;
  // if (!handler) return state;
  // return handler(state, action);
  return state;
};

export const navigationReducer = (state = {}, action: SetActiveMenuAction) => {
  const { type } = action;
  switch (type) {
    case SET_ACTIVE_MENU_ID:
      return ({
        ...state,
        activeMenuId: action.menuId,
      });
  }
  return state;
};
