import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    menu: {
                        home: "HOME",
                        services: "SERVICES",
                        products: "PRODUCTS",
                        contact: "CONTACT US",
                        about: "ABOUT FURRYEAR"
                    },
                    homepage: {
                      readmore: "Read More",
                      heroimage: {
                        title: "Pet portrait and Hand-made Ideas",
                        subtitle: "Your lovely furry friends are worth lovely gifts",
                        getstarted: "Get Started >"
                      },
                      popularproducts: "Popular Products",
                      price: "Price",
                      portraitprice: "from $40.00 AUD",
                      frameprice: "from $50.00 AUD",
                      badgeprice: "from $68.00 AUD",
                      from: "from",
                      singlepetportrait: "Single pet portrait",
                      petbadge: "Pet badge",
                      paintingwithframe: "Painting with frame",
                      howitworks: "Contact us to start your order",
                      showcases: "Show cases",
                      steps: {
                        title1: 'Contact us to start your order'
                      }
                    }
                }
            },
            cn: {
                translations: {
                    menu: {
                        home: "主页",
                        services: "服务",
                        products: "产品",
                        contact: "联系我们",
                        about: "关于毛耳朵"
                    },
                    homepage: {
                      readmore: "了解更多",
                      heroimage: {
                        title: "宠物定制肖像和创意饰品",
                        subtitle: "你的毛孩子值得拥有这份美丽的礼物",
                        getstarted: "开始定制 >"
                      },
                      popularproducts: "优选良品",
                      price: "价格",
                      portraitprice: "189元起",
                      frameprice: "246元起",
                      badgeprice: "320元起",
                      from: "价格",
                      singlepetportrait: "单只宠物肖像",
                      petbadge: "挂牌",
                      paintingwithframe: "打印画加画框",
                      howitworks: "联系客服下单",
                      showcases: "客户案例",
                      steps: {
                        title1: '联系客服下单'
                      }
                    }
                }
            }
        },
        fallbackLng: "en",
        debug: true,

        ns: ["translations"],
        defaultNS: "translations",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
