import React, { FunctionComponent } from 'react';
import './Button.scss';

export interface ButtonProps {}

const Button: FunctionComponent<ButtonProps> = ({ children }) => {
    return (
        <button className="fui-button">
            {children}
        </button>
    )
}

export default Button;