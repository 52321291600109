import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './Drawer.scss';

export interface DrawerProps {
    isShow: boolean;
    onClose?: (e: any) => void;
    placement?: 'left' | 'right';
}


const Drawer: FunctionComponent<DrawerProps> = ({
  isShow,
  onClose,
  children,
}) => (
    <>
        <div
            className={
                classNames(`fui-drawer fui-drawer--left`, isShow && 'fui-drawer--opened')
            }
        >
            { children }
        </div>
        {
            (
                <div
                    className={classNames('fui-drawer-overlay', isShow && 'fui-drawer-overlay--show' )}
                    onClick={onClose}
                >
                </div>
            )
        }
    </>
);

export default Drawer;