import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem';
import logoImg from '../../assets/logo.png';
import menuImg from '../../assets/menu.svg';
import leftImg from '../../assets/left.svg';
import './AppBar.scss';
import { Drawer } from '../Drawer';

export interface AppMenuProps {}

const AppBar: FunctionComponent<AppMenuProps> = () => {
    const { t, i18n } = useTranslation();
    const [showDrawer, setShowDrawer] = useState(false);
    const changeLanguage = (lang: string) => i18n.changeLanguage(lang);
    return (
        <header className="fui-app-bar">
            <div className="fui-app-bar__content">
                <img alt="" className="fui-app-bar__menu" src={menuImg} onClick={() => setShowDrawer(true)}/>
                <img alt="" className="fui-app-bar__logo" src={logoImg} />
                <Drawer isShow={showDrawer} onClose={() => setShowDrawer(false)}>
                    <div className="fui-app-drawer__header">
                        <img alt="" className="fui-app-drawer__close" src={leftImg} onClick={() => setShowDrawer(false)}/>
                    </div>
                    <div className="fui-app-drawer__content">
                        <MenuItem>{t('menu.home')}</MenuItem>
                        <MenuItem>{t('menu.products')}</MenuItem>
                        <MenuItem>{t('menu.contact')}</MenuItem>
                        <MenuItem>{t('menu.about')}</MenuItem>
                    </div>
                </Drawer>
                <div className="fui-app-menu">
                    <MenuItem>{t('menu.home')}</MenuItem>
                    <MenuItem>{t('menu.products')}</MenuItem>
                    <MenuItem>{t('menu.contact')}</MenuItem>
                    <MenuItem>{t('menu.about')}</MenuItem>
                </div>
                <div className="fui-app-bar__lang">
                    <span className="fui-app-bar__lang-switch" onClick={() => changeLanguage('cn')}>中</span>
                    <span> | </span>
                    <span className="fui-app-bar__lang-switch" onClick={() => changeLanguage('en')}>EN</span>
                </div>
            </div>
        </header>
    )
}

export default AppBar;