import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createStore, getHistory } from './store';
import { HomePage } from './pages/home';

import './index.scss';
import './i18n';

const store = createStore();

ReactDOM.render(
    <div className="fui-root">
        <Provider store={store}>
            <ConnectedRouter history={getHistory()}>
                <Switch>
                    <Route exact component={HomePage} path="/"/>
                </Switch>
            </ConnectedRouter>
        </Provider>
    </div>,
  document.getElementById('root')
);
